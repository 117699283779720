import { FC } from "react";
import { compact } from "lodash";

import useAccessType from "@hooks/use-access-type";
import useMemberFilterOptions from "@hooks/use-member-filter-options";

import Selectbox from "@components/Form/Selecbox";

import { useTodaysViewFiltersContext } from "./TodaysViewContext";

const TodaysViewMemberFilter: FC = () => {
  const { filters, setFilters } = useTodaysViewFiltersContext();
  const { hasFullAccess } = useAccessType();

  const { options: unformattedMembers } = useMemberFilterOptions();
  const memberOptions = compact(
    unformattedMembers?.map(({ value, text }) => {
      if (value === "all")
        return {
          label: "All members",
          value: "all-members",
        };
      return {
        label: text,
        value,
      };
    })
  );

  const handleMemberChange = (e) => {
    const targetVal = e.target.value;
    const value = targetVal === "all-members" ? undefined : targetVal;
    setFilters({
      memberId: value,
    });
  };

  const defaultValue = filters?.memberId ?? "all-members";

  const renderMembersPicker = hasFullAccess && (
    <div className="flex items-center justify-between">
      <Selectbox
        className="max-w-[180px] truncate"
        value={defaultValue}
        options={memberOptions}
        onChange={handleMemberChange}
      />
    </div>
  );

  return <div className="flex items-center gap-1">{renderMembersPicker}</div>;
};

export default TodaysViewMemberFilter;
