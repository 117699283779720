import { FC } from "react";

import { useSchedulers } from "@hooks/data/schedulers";
import { SchedulerType } from "@lib/data/schemas/scheduler";

import CalendarIcon from "@components/Icons/CalendarIcon";
import SchedulerPicker from "@components/SchedulerPicker";
import TypeIllustration from "@components/TypeIllustration";

import CustomDropdown from "./CustomDropdown";
import DefaultFilterDescriptor from "./DefaultFilterDescriptor";
import TodaysViewCard from "./TodaysViewCard";
import {
  useFetchTodaysViewSessionsByScheduler,
  useTodaysViewFiltersContext,
} from "./TodaysViewContext";

const SessionsBySchedulerCard: FC = () => {
  const { data: response, isLoading } = useFetchTodaysViewSessionsByScheduler();
  const { filters, setFilters } = useTodaysViewFiltersContext();

  const schedulerId = filters?.schedulerId;

  const { data: schedulers } = useSchedulers();
  const metricData = response?.data;

  const hasSchedulers = !!schedulers?.length;
  const scheduler = schedulers?.find(
    (scheduler) => scheduler.id === schedulerId
  );

  const { icon, title } = scheduler ?? {};

  const filterDescription = scheduler ? (
    <div className="flex items-center justify-center gap-1 w-full">
      {icon && <TypeIllustration slug={icon} height={24} width={24} />}
      <div className="font-medium truncate">{title}</div>
    </div>
  ) : (
    <DefaultFilterDescriptor Icon={CalendarIcon} type="schedulers" />
  );

  const onSchedulerChange = (value: SchedulerType) => {
    setFilters({ schedulerId: value.id });
  };

  const customFilter = hasSchedulers ? (
    <SchedulerPicker
      schedulers={schedulers}
      onSchedulerChange={onSchedulerChange}
      className="!mt-0 shadow-none"
      customPlaceholder={<CustomDropdown />}
      label={null}
      customMenuClassNames="!absolute !w-72 !mt-2"
    />
  ) : null;

  return (
    <TodaysViewCard
      title="Sessions by scheduler"
      metricData={metricData}
      tooltipCopy="Sessions booked via all schedulers, or a specific scheduler type. It's possible that some appointments do not have an associated scheduler."
      filterDescription={filterDescription}
      customFilter={customFilter}
      isLoading={isLoading}
      totalId="sessions-by-scheduler-total"
    />
  );
};

export default SessionsBySchedulerCard;
